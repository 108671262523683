.descContentWrap {
  margin: 32px 0;
}

.descContent {
  // padding: 0 0 28px;
  padding: 0;

  .descTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // margin-bottom: 20px;
  }

  h3 {
    font-size: 20px;
    line-height: 28px;
    color: #121212;
  }

  .skills {
    display: flex;
    flex-wrap: wrap;

    // margin-bottom: 32px;

    span {
      box-sizing: content-box;
      display: inline-block;
      align-items: center;
      max-width: 200px;
      height: 24px;
      padding: 6px 10px;
      margin: 12px 8px 0 0;
      overflow: hidden;
      font-size: 16px;
      line-height: 24px;
      color: #515151;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: #fff;
      border: 1px solid #ebeef0;
      border-radius: 8px;
    }
  }

  .richText {
    padding-top: 32px;
    font-size: 16px;
    line-height: 28px;

    :global {

      /* stylelint-disable-next-line selector-class-pattern */
      .ql-editor {
        padding: 0;
        margin: 0;

        ul {
          padding: 0;
          margin: 0;


        }

        ol {
          padding-left: 7px
        }

        li {
          padding-left: 0.8em
        }
      }
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    >* {
      color: #515151;
      word-break: break-word;
    }

    // ul,
    // ol {
    //   margin-left: 15px;
    // }

    h4 {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 700;
      color: #000;
    }
  }

  .extraInfo {
    padding: 20px 16px;
    margin-top: 24px;
    font-size: 16px;
    background: #f8f9fa;
    background-color: #f8f9fa;
    border-radius: 12px;
  }

  .extraInfo .row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;

    p {
      display: flex;
      flex: 1;
      flex-direction: column;
      line-height: 28px;
      color: #111212;

      span {
        width: 100%;
        margin: 0;
        margin-bottom: 5px;
        font-weight: normal;
      }

      span:last-child {
        margin-bottom: 0;
      }
    }
  }

  .extraInfo .label {
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    color: #111212;
  }

  .extraInfo .row:first-of-type {
    margin-top: 0;
  }
}

.identity {
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 36px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #121212;
  background: #f2f8fb;
  border-radius: 8px;

  svg {
    margin-right: 8px;
  }
}